import React from 'react'
import styled from 'styled-components'

import { useI18n } from '@bestyled/primitives'
import mediaqueries from '../../styles/media'
import Section from '../../components/Section'



const SpecialistsHero: React.FC<{ state?: string }> = ({ state }) => {
  const { t } = useI18n()
  const stateText = state ? ` ${t('Slipway.in')} ${state}` : ''

  return (
    <Section relative id="Specialists__Hero">
      <HeadingContainer style={{ maxWidth: `${t('site.hero.maxWidth')}px` }}>
        <HeroHeading
          dangerouslySetInnerHTML={{
            __html: `${t('Slipway.Choose a specialist')}${stateText}`
          }}
        />
      </HeadingContainer>
    </Section>
  )
}

export default SpecialistsHero

const HeadingContainer = styled.div`
  margin: 30px 0;

  ${mediaqueries.desktop`
    width: 80%;
  `}

  ${mediaqueries.tablet`
    width: 100%;
  `}
`

const HeroHeading = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 52px;
  line-height: 1.15;
  color: ${(p) => p.theme.colors.primary};

  a {
    color: ${(p) => p.theme.colors.accent};
  }

  ${mediaqueries.desktop`
    font-size: 38px
  `}

  ${mediaqueries.phablet`
    font-size: 22px;
  `}
`
