import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

import { useI18n } from '@bestyled/primitives'
import { useRemark, Remark } from 'react-remark'

import { ImagePlaceholder, Headings, BookingButton } from '../../components/TComponents'

import Image from '../../components/Image'
import mediaqueries from '../../styles/media'

/**
 * Tiles
 * [SHORT], [SHORT], [SHORT]
 * [SHORT], [SHORT], [SHORT]
 * [SHORT], [SHORT], [SHORT]
 */

interface SpecialistsListProps {
  specialists: any
  alwaysShowAllDetails?: boolean
}

interface SpecialistsListItemProps {
  specialist: any
}

const MINIARRAY_SIZE = 3

const SpecialistsList: React.FC<SpecialistsListProps> = ({ specialists, alwaysShowAllDetails }) => {
  /**
   * We're taking the flat array of articles [{}, {}, {}...]
   * and turning it into an array of miniarrays of articles [[{}, {}], [{}, {}], [{}, {}]...]
   * This makes it simpler to create the grid we want
   */
  const specialistMiniArrays = specialists.reduce((result, value, index, array) => {
    if (index % MINIARRAY_SIZE === 0) {
      result.push(array.slice(index, index + MINIARRAY_SIZE))
    }
    return result
  }, [])

  return (
    <SpecialistsListContainer alwaysShowAllDetails={alwaysShowAllDetails}>
      {specialistMiniArrays.map((specialistMiniArray, index) => {
        return (
          <ListTile key={index}>
            {specialistMiniArray.map((specialist) => (
              <ListItem key={specialist.name} specialist={specialist} />
            ))}
          </ListTile>
        )
      })}
    </SpecialistsListContainer>
  )
}

export default SpecialistsList

const ListItem: React.FC<SpecialistsListItemProps> = ({ specialist }) => {
  const { t } = useI18n()

  if (!specialist) {
    return null
  }
  const hasOverflow = specialist.title.length > 35
  const imageSource = specialist.avatar.regular
  const hasHeroImage = imageSource && Object.keys(imageSource).length !== 0 && imageSource.constructor === Object

  return (
    <SpecialistCard>
      <SpecialistLink to={t(specialist.slug)} data-a11y='false'>
        <ListItemTile>
          <ImageContainer>
            {hasHeroImage ? <Image src={{ ...imageSource, aspectRatio: 1 }} /> : <ImagePlaceholder />}
          </ImageContainer>
          <div>
            {specialist.newpatients ? (
              <MetaDataNewPatients>
                <BookingButton height={18} width={300} mt={0} to={`${specialist.slug}/booking`}>
                  {t('Slipway.Request appointment')}
                </BookingButton>
              </MetaDataNewPatients>
            ) : (
              <MetaDataNewPatients>{t('Slipway.Not Accepting new')}</MetaDataNewPatients>
            )}
            <Title hasOverflow={hasOverflow}>{specialist.name}</Title>
            <Excerpt hasOverflow={hasOverflow}>{specialist.excerpt}</Excerpt>
            <MetaDataCredentials>{specialist.suffix}</MetaDataCredentials>
            {specialist.intro && (
              <MetaData>
                <Remark>{t(specialist.intro)}</Remark>
              </MetaData>
            )}
            {!specialist.intro && (
              <React.Fragment>
                <MetaData>
                  {t('Slipway.Specialties')}: {specialist.specialties}
                </MetaData>
                {specialist.couples === 'Yes' ? (
                  <MetaData>
                    {t('Slipway.Couples')}:{t('Slipway.Yes')}
                  </MetaData>
                ) : (
                  <MetaData>
                    {t('Slipway.Couples')}:{t('Slipway.No')}
                  </MetaData>
                )}
                <MetaData>
                  {t('Slipway.Ages')}: {specialist.ages}
                </MetaData>
              </React.Fragment>
            )}
          </div>
        </ListItemTile>
      </SpecialistLink>
    </SpecialistCard>
  )
}

const SpecialistCard = styled.div`
  ${mediaqueries.phone`
    padding-bottom: 60px  `}
`

const limitToTwoLines = css`
  text-overflow: ellipsis;
  overflow-wrap: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  white-space: normal;
  overflow: hidden;

  ${mediaqueries.phablet`
    -webkit-line-clamp: 3;
  `}
`

const showDetails = css`
  p {
    display: -webkit-box;
  }

  h2 {
    margin-bottom: 10px;
  }
`

const SpecialistsListContainer = styled.div<{ alwaysShowAllDetails?: boolean }>`
  transition: opacity 0.25s;
  ${(p) => p.alwaysShowAllDetails && showDetails}
`

const ListTile = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(${MINIARRAY_SIZE}, 1fr);
  grid-template-rows: 2;
  column-gap: 30px;

  &:not(:last-child) {
    margin-bottom: 75px;
  }

  ${mediaqueries.tablet`
    grid-template-columns: 1fr;
    
    &:not(:last-child) {
      margin-bottom: 0;
    }
  `}
`

const ListItemTile = styled.div`
  position: relative;

  ${mediaqueries.tablet`
    margin-bottom: 60px;
  `}

  @media (max-width: 540px) {
    background: ${(p) => p.theme.colors.card};
  }

  ${mediaqueries.phablet`
    margin-bottom: 40px;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  `}
`

const ImageContainer = styled.div`
  position: relative;
  box-shadow: 0 30px 60px -10px rgba(0, 0, 0, 0.3), 0 18px 36px -18px rgba(0, 0, 0, 0.33);
  margin-bottom: 30px;
  transition: transform 0.3s var(--ease-out-quad), box-shadow 0.3s var(--ease-out-quad);

  & > div {
    height: 100%;
  }

  ${mediaqueries.tablet`
    margin-bottom: 35px;
  `}

  ${mediaqueries.phablet`
    overflow: hidden;
    margin-bottom: 5px;
    box-shadow: none;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  `}
`

const Title = styled(Headings.h2 as React.FC<{ hasOverflow: boolean }>)`
  font-size: 21px;
  font-family: ${(p) => p.theme.fonts.serif};
  margin-bottom: ${(p) => p.hasOverflow && '35px'};

  transition: color 0.3s ease-in-out;
  ${limitToTwoLines};

  ${mediaqueries.desktop`
    margin-bottom: 15px;
  `}

  ${mediaqueries.tablet`
    font-size: 24px;  
  `}

  ${mediaqueries.phablet`
    font-size: 18px;  
    padding: 30px 20px 0;
    margin-bottom: 10px;
    -webkit-line-clamp: 3;
  `}
`

const Excerpt = styled.p<{
  hasOverflow: boolean
}>`
  ${limitToTwoLines};
  font-size: 16px;
  margin-bottom: 10px;
  color: ${(p) => p.theme.colors.grey};
  display: ${(p) => p.hasOverflow && 'none'};
  max-width: 515px;

  ${mediaqueries.desktop`
    display: -webkit-box;
  `}

  ${mediaqueries.phablet`
    margin-bottom; 15px;
  `}

  ${mediaqueries.phablet`
    font-size: 12px;
    max-width: 100%;
    padding:  0 20px;
    margin-bottom: 20px;
    -webkit-line-clamp: 3;
  `}
`

const MetaData = styled.div`
  font-weight: 600;
  font-size: 12px;
  color: ${(p) => p.theme.colors.grey};
  opacity: 0.75;

  & > ul {
    position: relative;
    list-style: none;
    padding-bottom: 1vh;
    li {
      color: ${(p) => p.theme.colors.grey};
    }
  }

  ${mediaqueries.phablet`
    max-width: 100%;
    padding:  0 20px 10px;
  `}
`
const MetaDataNewPatients = styled.div`
  padding: 0px 0px 10px;
  font-weight: 600;
  font-size: 12px;
  color: ${(p) => p.theme.colors.accent};
  opacity: 0.75;

  ${mediaqueries.phablet`
    max-width: 100%;
    padding:  5px 20px 10px;
    position: relative;
  `}
`
const MetaDataCredentials = styled.div`
  font-weight: 600;
  font-size: 12px;
  color: ${(p) => p.theme.colors.brand};
  opacity: 0.75;

  ${mediaqueries.phablet`
    max-width: 100%;
    padding:  0 20px 10px;
  `}
`

const SpecialistLink = styled(Link)`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 5px;
  z-index: 1;
  transition: transform 0.33s var(--ease-out-quart);
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  &:hover ${ImageContainer}, &:focus ${ImageContainer} {
    transform: translateY(-1px);
    box-shadow: 0 50px 80px -20px rgba(0, 0, 0, 0.27), 0 30px 50px -30px rgba(0, 0, 0, 0.3);
  }

  &:hover h2,
  &:focus h2 {
    color: ${(p) => p.theme.colors.accent};
  }

  &[data-a11y='true']:focus::after {
    content: '';
    position: absolute;
    left: -1.5%;
    top: -2%;
    width: 103%;
    height: 104%;
    border: 3px solid ${(p) => p.theme.colors.accent};
    background: rgba(255, 255, 255, 0.01);
    border-radius: 5px;
  }

  ${mediaqueries.phablet`
    &:hover ${ImageContainer} {
      transform: none;
      box-shadow: initial;
    }

    &:active {
      transform: scale(0.97) translateY(3px);
    }
  `}
`
